<template>
  <div class="w-full">
    <v-text-field
      v-if="showSearch"
      label="Cerca indirizzo"
      id="searchLocation"
      v-model="address"
      @keydown.enter="searchLocation"
    ></v-text-field>
    <div class="google-map h-104" ref="googleMap"></div>
    <google-map-marker
      :google="google"
      :map="map"
      v-for="(marker, m) in markers"
      :key="m"
      :marker="marker"
    ></google-map-marker>
    <template v-if="!!google && map">
      <slot :google="google" :map="map"></slot>
    </template>
  </div>
</template>

<script>
import GoogleMapsApiLoader from 'google-maps-api-loader'
import GoogleMapMarker from './GoogleMapMarker.vue'
export default {
  components: { GoogleMapMarker },
  props: {
    mapConfig: Object,
    showSearch: {
      type: Boolean,
      default: false
    },
    markerList: Object
  },
  data() {
    //TODO: [ITR-190] Create google map component with search
    return {
      google: null,
      map: null,
      geocoder: null,
      markers: [],
      address: ''
    }
  },
  async mounted() {
    const googleMapApi = await GoogleMapsApiLoader({
      apiKey: process.env.GOOGLE_MAPS_API_KEY,
      region: 'it' //this.$i18n.locale
      //libraries: ["places"]
    })
    this.google = googleMapApi
    this.initializeMap()
    console.log('INIZIALIZZATO GOOGLE MAPS', this.markerList)
    if (this.markerList && this.markerList.lat && this.markerList.lng) {
      this.addMarker({ lat: this.markerList.lat, lng: this.markerList.lng })
    }
    //this.markers.push(this.markerList)
  },
  computed: {
    computedAddress: {
      get() {
        return this.address
      }
    }
  },
  methods: {
    initializeMap() {
      const mapContainer = this.$refs.googleMap
      this.map = new this.google.maps.Map(mapContainer, {
        ...this.mapConfig
      })
      this.geocoder = new this.google.maps.Geocoder()
      this.map.addListener('click', e => {
        this.address = ''
        this.addMarker({ lat: e.latLng.lat(), lng: e.latLng.lng() })
      })
    },

    addMarker(e) {
      this.markers.forEach(mk => mk.setMap(null))
      this.markers = []
      let mk = new this.google.maps.Marker({
        position: { lat: e.lat, lng: e.lng },
        map: this.map
      })
      if (!this.address) {
        this.geocoder.geocode({ latLng: mk.getPosition() }, (res, stat) => {
          if (stat == 'OK') {
            this.address = res[0].formatted_address
            this.$emit('addMarker', {
              marker: this.markers,
              address: this.address
            })
          }
        })
      }
      this.markers.push(mk)
      this.$emit('addMarker', { marker: this.markers, address: this.address })
    },
    searchLocation() {
      this.geocoder.geocode({ address: this.address }, (result, status) => {
        if (status == 'OK') {
          this.address = result[0].formatted_address
          this.map.setCenter(result[0].geometry.location)
          this.map.setZoom(17)
          this.addMarker({
            lat: result[0].geometry.location.lat(),
            lng: result[0].geometry.location.lng()
          })
        } else {
          console.log('Errore di geocoding', status)
        }
      })
    }
  }
}
</script>

<style scoped>
input {
  border: 2px solid red;
}
</style>
