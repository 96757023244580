/* eslint-disable vue/valid-template-root */
<template functional>
  <span class="hidden"></span>
</template>
<script>
// import { POINT_MARKER_ICON_CONFIG } from '@/constants/mapSettings'

export default {
  props: {
    google: {
      type: Object,
      required: true
    },
    map: {
      type: Object,
      required: true
    },
    marker: {
      type: Object,
      required: true
    }
  },

  mounted() {
    const imageURL = '@/assets/map_pin.png'
    let pin = {
      url: imageURL,
      size: new this.google.maps.Size(40, 50),
      origin: new this.google.maps.Point(0, 0),
      anchor: new this.google.maps.Point(20, 50)
    }
    new this.google.maps.Marker({
      position: this.marker.position,
      marker: this.marker,
      map: this.map,
      icon: pin
    })
  }
}
</script>
