<template>
  <button
    @click="$emit('click')"
    :class="[
      ...buttonClasses,
      { 'cursor-not-allowed': disabled },
      { 'opacity-50': disabled }
    ]"
    :disabled="disabled"
    class="rounded-md hover:opacity-80 w-full px-8 py-3"
  >
    <span v-if="loading">
      <i class="mdi mdi-loading mdi-spin fill-current text-primary-700"></i>
    </span>
    <template v-else>
      <slot></slot>
    </template>
  </button>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    color: {
      type: String,
      default: 'primary'
    },
    disabled: {
      type: Boolean,
      default: false
    },
    buttonClasses: {
      type: Array,
      default: () => []
    }
  }
  // computed: {
  //   buttonColor() {
  //     return [
  //       `text-${this.color}-100`,
  //       `bg-${this.color}-500`,
  //       'border',
  //       `border-${this.color}-500`
  //     ]
  //   }
  // }
}
</script>

<style lang="postcss" scoped>
button.btn {
  @apply px-8 py-1 border border-primary-500;
}
button[type='submit'] {
  @apply px-8 py-1;
}
</style>
